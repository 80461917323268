import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import FrontEndDevNav from '../header/FrontEndDevNav';
import ActiveSection from '../../scripts/active-section';
import { Link } from 'react-router-dom';

const FrontEndDeveloper = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <FrontEndDevNav ref={menuRef} />
      <header className="main-header flex-group flex-start flex-align-center">
        <h4>Senior Hybrid UX/UI & Front-end Developer</h4>
        <Link className="go-home" to="/LandingPage">Go Back</Link>
      </header>
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element>
          <section id="section-zero" className='content-section flex-group flex-center'>
            <ActiveSection index="0" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2 className='dark-grey'>15 Years Experience</h2>
                <p className='flex-center'>
                  "So, I have just over 15 years of experience in UX/UI design and front-end 
                  development. Over the years, I’ve worked across a wide range of industries, 
                  including healthcare, entertainment, broadcasting, enterprise software, 
                  and mining. I'm currently at Anglo American, focusing on 
                  their commodity trading platform. Along the way, I’ve had the opportunity 
                  to collaborate with leading global brands like Universal Music, 
                  IBM, BBC, and Turner Broadcasting."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-one" className='content-section flex-group flex-center'>
          <ActiveSection index="1" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Design Systems</h2>
              <p className='flex-center'>
                "At Anglo American, one of my key contributions has been leading the 
                development of a comprehensive Angular Component Design System. This 
                system was built from scratch and now serves as the backbone for over 
                150 developers, providing a robust library of reusable components. 
                These components support a diverse range of applications, including 
                a suite of trading tools."
              </p>
              <p>
                "What’s been particularly rewarding is seeing how this system has 
                streamlined workflows, improved consistency, and sped up development 
                timelines. I also work closely with developers to ensure that the 
                implementation stays true to the design principles and adheres to 
                best practices."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-two" className='content-section flex-group flex-center'>
          <ActiveSection index="2" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Cross-functional Teams</h2>
              <p className='flex-center'>
                "I’ve always valued collaboration, and I work closely with stakeholders, 
                business analysts, and development teams to ensure the best possible 
                outcomes. For example, I partner with BAs to refine user flows, 
                wireframes, and information architecture, just to ensure we maintain a 
                user-centred focus throughout. By staying involved from concept to 
                implementation, I help bridge the gap between design and development."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-three" className='content-section flex-group flex-center'>
          <ActiveSection index="3" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Technical expertise</h2>
              <p className='flex-center'>
                "My technical background is something I’m particularly proud of. I’ve worked 
                extensively with Angular and TypeScript and substancially with React for front-end development. 
                When it comes to styling and layout, I’m highly experienced in SASS, CSS Grid, 
                and Flexbox. I’m also well-versed in using tools like NPM, Node.js, and Git 
                for efficient workflows and Azure pipelines for CI/CD."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-four" className='content-section flex-group flex-center'>
          <ActiveSection index="4" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Problem Solving</h2>
              <p className='flex-center'>
                "In terms of problem-solving, one of my most impactful projects at Anglo 
                American has been leading the Design Language Project I had complete ownership of this project. 
                It centred on creating a comprehensive library of components that adhered to 
                branding guidelines while addressing the varied needs of users across the organisation."
              </p>
              <p>
                "A significant focus is on enhancing the user journeys within Anglo’s trading applications. 
                These tools play a pivotal role in enabling commodity trades, so it's important 
                to design workflows that streamlined complex processes. For instance, I crafted a 
                modular design for data grids that could handle extensive datasets, allowing users 
                to sort, filter, and interact with the information"
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-five" className='content-section flex-group flex-center'>
          <ActiveSection index="5" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Teams & Collaboration</h2>
              <p className='flex-center'>
                "I've got quite a few years experience working in agile teams, contributing to 
                sprints and iterative design processes. I have experince of working in both Azure and AWS."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-six" className='content-section flex-group flex-center'>
          <ActiveSection index="6" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Initial Phase</h2>
              <p className='flex-center'>
                "Every new project begins with a kick-off meeting to align on goals, scope, 
                and requirements. At Anglo, for the trading applications, this involved 
                defining workflows and user interactions specific to traders and analysts, 
                ensuring the design system components could adapt to their needs. If we didn't 
                already have a suitable component within the Design Language, 
                then I would go ahead and build that."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-seven" className='content-section flex-group flex-center'>
          <ActiveSection index="7" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Wireframing & Approval</h2>
              <p className='flex-center'>
                So I'd create wireframes and low-fidelity prototypes to visualise solutions and 
                align with stakeholders. For example, in the Security Model App project, 
                I designed workflows to streamline role-based permissions and approval 
                processes. Once approved, these designs served as a blueprint for the 
                frontend development phase.
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-eight" className='content-section flex-group flex-center'>
          <ActiveSection index="8" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Front-end Development</h2>
              <p>
                With designs finalised, I transition to building responsive and functional 
                interfaces. At Anglo, this often involved creating new components in the 
                Angular-based Design Language System when existing ones didn’t meet 
                project requirements. For the trading apps, I developed interactive data 
                grids and visualisation tools that allowed users to manage complex 
                datasets efficiently."
              </p>
              <p>
                At Universal Music, my role extended to both designing and building the 
                frontend for a suite of applications, including StudioHub and RightsHub. 
                I implemented responsive interfaces using Angular, TypeScript, and SASS, 
                ensuring seamless functionality across devices while 
                maintaining brand consistency.
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-nine" className='content-section flex-group flex-center'>
          <ActiveSection index="9" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Component Creation & Collaboration</h2>
              <p>
                So building scalable, reusable components is central to my process. 
                Here at Anglo, I've ensured new components adher to the Design Language 
                System, integrating them seamlessly into existing applications. 
                I also worked closely with developers, offering support and 
                conducting regular UX reviews to ensure implementation 
                matched the original designs.
               </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-ten" className='content-section flex-group flex-center'>
          <ActiveSection index="10" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Testing Phase</h2>
                <p>
                  "I conduct testing to ensure components are both functional and 
                  accessible. At Anglo, I used tools like Selenium and Wave to validate 
                  compliance with accessibility standards, and I participated in 
                  iterative testing to optimise workflows in trading apps.""
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-eleven" className='content-section flex-group flex-center'>
          <ActiveSection index="11" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Delivery and Continuous Improvement</h2>
                <p>
                  Post-launch, I monitor application performance and gather user 
                  feedback to plan updates. At Anglo, feedback from traders has 
                  guided refinements to Design Language components, while at UMG, 
                  feedback from label managers after the launch of StudioHub 
                  informed enhancements to the onboarding process and other key workflows.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default FrontEndDeveloper;