import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Link, Element } from 'react-scroll';

import Header from '../header/Header';
import IntroSection from './IntroSection';
import AboutMike from './About';
import Approach from './Approach';
import MikesProjects from './Projects';
import ContactMike from './Contact';

import ActiveSection from '../../scripts/active-section';

const Home = () => {
  var menuRef = React.createRef();

  return (
    <section className="content">
      <ReactBody className="home-page" />

      {/* Fixed Navigation at the Top */}
      <nav className="nav-bar">
        <Link to="section-home" smooth={true} offset={-45} duration={500}>
          Home
        </Link>
        <Link to="section-about" smooth={true} offset={-45} duration={500}>
          About
        </Link>
        <Link to="section-approach" smooth={true} offset={-45} duration={500}>
          Approach
        </Link>
        <Link to="section-projects" smooth={true} offset={-45} duration={500}>
          Projects
        </Link>
        <Link to="section-contact" smooth={true} offset={-45} duration={500}>
          Contact
        </Link>
      </nav>

      <StickyContainer>
        {/* Section: Header */}
        <section id="section-home">
          <Element name="section-home">
            <ActiveSection index="0" refToMenu={menuRef}>
              <Header ref={menuRef} />
            </ActiveSection>
          </Element>
        </section>

        {/* Section: Intro */}
        <Element name="section-home">
          <section className="new-section home">
            <IntroSection />
          </section>
        </Element>

        {/* Section: About */}
        <Element name="section-about">
          <section className="new-section about" id="section-about">
            <ActiveSection index="1" refToMenu={menuRef}>
              <AboutMike />
            </ActiveSection>
          </section>
        </Element>

        {/* Section: Approach */}
        <Element name="section-approach">
          <section className="new-section approach" id="section-approach">
            <ActiveSection index="2" refToMenu={menuRef}>
              <Approach />
            </ActiveSection>
          </section>
        </Element>

        {/* Section: Projects */}
        <Element name="section-projects">
          <section className="new-section projects" id="section-projects">
            <ActiveSection index="3" refToMenu={menuRef}>
              <MikesProjects />
            </ActiveSection>
          </section>
        </Element>

        {/* Section: Contact */}
        <Element name="section-contact">
          <section className="new-section contact" id="section-contact">
            <ActiveSection index="4" refToMenu={menuRef}>
              <ContactMike />
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
    </section>
  );
};

export default Home;
