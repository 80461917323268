import { React } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AddClass from './scripts/transpose-menu';
import AOS from 'aos';

// pages
import AA from './components/pages/AA';
import UMG from './components/pages/UMG';
import Turner from './components/pages/Turner';
import BBC from './components/pages/BBC';
import Monum from './components/pages/Monum';
import Setanta from './components/pages/Setanta';
import Guinness from './components/pages/Guinness';
import PrivateJobs from './components/pages/Private';
import Home from './components/pages/Home';
import BlogHome from './components/pages/blog/components/Layout';
import PostsList from "./components/pages/blog/features/posts/PostsList";
import AddPostForm from "./components/pages/blog/features/posts/AddPostForm";
import SinglePostPage from "./components/pages/blog/features/posts/SinglePostPage";
import EditPostForm from "./components/pages/blog/features/posts/EditPostForm";
import UsersList from "./components/pages/blog/features/users/UsersList";
import UserPage from './components/pages/blog/features/users/UserPage';

import LandingPage from './components/interviews/LandingPage';
import ProductDesigner from './components/interviews/ProductDesigner';
import FrontEndDeveloper from './components/interviews/FrontEndDeveloper';

AOS.init();

function App() {
  return (
    <div className="App">
      <AddClass>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/AA' element={<AA />} />
          <Route path='/UMG' element={<UMG />} />
          <Route path='/Turner' element={<Turner />} />
          <Route path='/BBC' element={<BBC />} />
          <Route path='/Monum' element={<Monum />} />
          <Route path='/Setanta' element={<Setanta />} />
          <Route path='/Guinness' element={<Guinness />} />
          <Route path='/Private' element={<PrivateJobs />} />

          {/* Interviews */}
          <Route path='/LandingPage' element={<LandingPage />} />
          <Route path='/ProductDesigner' element={<ProductDesigner />} />
          <Route path='/FrontEndDeveloper' element={<FrontEndDeveloper />} />

          {/* Blog */}
          <Route exact path="/blog-home" element={<BlogHome />}>
            <Route index element={<PostsList />} />
            <Route path="post">
              <Route index element={<AddPostForm />} />
              <Route path=":postId" element={<SinglePostPage />} />
              <Route path="edit/:postId" element={<EditPostForm />} />
            </Route>
            <Route path="user">
              <Route index element={<UsersList />} />
              <Route path=":userId" element={<UserPage />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AddClass>
    </div>
  );
}

export default App;