import React from 'react';
import ReactPlayer from 'react-player';

export default props => {
// reference here https://video-react.js.org/
  return (
    <ReactPlayer
      url="../aa/core-landing-page.mp4"
      controls
      width="100%"
      height="100%"
      muted
      playing
      loop
    />
  );
};