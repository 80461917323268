import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import ProductDesNav from '../header/ProductDesNav';
import ActiveSection from '../../scripts/active-section';
import { Link } from 'react-router-dom';

const ProductDesigner = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <ProductDesNav ref={menuRef} />
      <header className="main-header flex-group flex-start flex-align-center">
        <h4>Senior UX/UI Product Designer</h4>
        <Link className="go-home" to="/LandingPage">Go Back</Link>
      </header>
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element>
          <section id="section-zero" className='content-section flex-group flex-center'>
            <ActiveSection index="0" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2 className='dark-grey'>15 Years Experience</h2>
                <p className='flex-center'>
                  "So I have just over 15 years of experience now in Product Design UX/UI 
                  across various industries, healthcare, entertainment, 
                  broadcasting, enterprise software and more recently mining at Anglo American working 
                  on their commodity trading platform. I’ve collaborated with global brands like 
                  Anglo American, Universal Music, IBM, and BBC."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-one" className='content-section flex-group flex-center'>
          <ActiveSection index="1" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Design Systems</h2>
              <p className='flex-center'>
                "At both Universal Music Group and Anglo American, I led the design, branding, 
                and development of comprehensive design systems. These serve as a catalogue of 
                components, becoming the building blocks for Anglo American’s applications, 
                including."
              </p>
              <p>
                "The resource has significantly improved design 
                consistency and accelerated development timelines. It's a leadership role, I oversee 
                two junior designers and support roughly 120 developers, ensuring the designs are implemented correctly."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-two" className='content-section flex-group flex-center'>
          <ActiveSection index="2" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Cross-functional Teams</h2>
              <p className='flex-center'>
                "I work across multiple teams, these include developers, stakeholders, and product 
                teams to ensure high quality design handoffs"
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-three" className='content-section flex-group flex-center'>
          <ActiveSection index="3" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Design Tools</h2>
              <p className='flex-center'>
                "I've used quite a few different design tools over the years but my preference is Figma. 
                I particularly like the Design Tokens for Figma. It just makes theming more streamlined and consistent."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-four" className='content-section flex-group flex-center'>
          <ActiveSection index="4" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Problem Solving</h2>
              <p className='flex-center'>
                "In terms of problem solving, I've tackled various design challenges like addressing false 
                rights claims for Universal Music’s legacy systems by creating user-friendly applications 
                that streamlined data management."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-five" className='content-section flex-group flex-center'>
          <ActiveSection index="5" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Teams & Collaboration</h2>
              <p className='flex-center'>
                "I've got quite a few years experience working in agile teams, contributing to 
                sprints and iterative design processes. I have experince of working in both Azure and AWS."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-six" className='content-section flex-group flex-center'>
          <ActiveSection index="6" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Initial Phase</h2>
              <p className='flex-center'>
                "We usually have a kick-off session with the stakeholders to clarify the project scope 
                and user needs. Sometimes this is with the BA, other times with senior management."
              </p>
              <hr className='w-100' />
              <h3>Example</h3>
              <p className='green'>
                "So just as an example, when working on the StudioHub project at Universal Music, I started by meeting with 
                all the labels to understand their challenges—like managing contributors and tracks—and 
                aligning on goals like improving collaboration and reducing false rights claims which was an issue they 
                needed to address at the time. Each label has a slightly different way of doing things, so they needed 
                the ability to personalise their space."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-seven" className='content-section flex-group flex-center'>
          <ActiveSection index="7" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Research Phase</h2>
              <p className='flex-center'>
                "Interviews, and competitor analysis to gather data and define user personas.
                This will usually include brainstorming sessions, group discussions, stakeholder Workshops,
                lots of User Journey Mappings on WhiteBoards (Navigation & Workflows) that kind of thing.
              </p>
              <hr className='w-100' />
              <h3>Example</h3>
              <p className='green'>
                "At Anglo, last year we initiated a major onboarding project. I conducted in-depth interviews with 
                onboarding managers to uncover pain points in the SKYC workflow. This research allowed me to 
                develop detailed personas and map out their core needs. The process was complex, as there were 
                three separate systems for different phases, all of which needed to integrate seamlessly."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-eight" className='content-section flex-group flex-center'>
          <ActiveSection index="8" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Ideation & Concept Phase</h2>
              <p>
                "So the next step is to develop wireframes based on findings, sketches, and low-fidelity prototypes 
                to explore solutions. Collaborate with the team for feedback. You don’t want 
                to spend too much time on aesthetics at this point, it’s more about 
                thrashing out ideas"
              </p>
              <hr className='w-100' />
              <h3>Example:</h3>
              <p className='green'>
                "A recent project at Anglo was the Security Model Project (AMP) which is a permissions based App 
                where you can assign users with different roles and responsibilities, there's also an approval process involved with that. 
                For this I prodiced several workflows and testing sessions, to fine-tune the final solution. 
                It's a widely used project across the organisation"
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-nine" className='content-section flex-group flex-center'>
          <ActiveSection index="9" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>High-Fidelity Design Phase</h2>
              <p>
                Create detailed designs using tools like Figma, ensuring consistency with the design system. 
                Build interactive prototypes for usability testing.
              </p>
              <hr className='w-100' />
              <h3>Example</h3>
              <p className='green'>
                "After finalising the workflows, I would create high-fidelity designs in Figma, aligning 
                them with the guidelines (Some of thse guidelines I would have created myself for 
                example here at Anglo but also at UMG). I also used Figma's prototyping features to 
                simulate user flows for usability testing."
               </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-ten" className='content-section flex-group flex-center'>
          <ActiveSection index="10" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Testing Phase</h2>
                <p>
                  Conduct usability tests with real users, gather feedback, and iterate on the design to address 
                  any issues. This invloves a combination of:<br/>
                </p>
                <p>
                  <strong className='dark-grey'>A/B Testing: </strong><br/>
                  Comparing different design versions to determine which performs better in terms of 
                  usability and user satisfaction.<br/>

                  <strong className='dark-grey'>Task-Based Testing:</strong><br/>
                  Asking users to perform specific tasks (like navigating through workflows or 
                  distributing content) to assess the ease of use.<br/>

                  <strong className='dark-grey'>Contextual Inquiry: </strong><br/>
                  Observing users as they engage with the project in their actual work environment to gain deeper 
                  insights into their workflow.<br/>

                  <strong className='dark-grey'>Heuristic Evaluation: </strong><br/>
                  Heuristic Evaluation: Applying usability principles and heuristics to identify design flaws, 
                  improve user interactions, and enhance the overall user experience. We sometimes use usability 
                  experts for this part. We used ‘User Vison’ for our Onboarding KYC project.
                </p>
                <p>
                  As a team we also run regular UX Reviews. These reviews include usability and accessability 
                  testing with tools like Selenium and Wave, but also we run our apps through the W3C and DDA Compliance checks.
                </p>
                  <hr className='w-100' />
                  <h3>Example:</h3>
                <p className='green'>
                  "We ran usability tests with label managers and engineers, which revealed issues like unclear navigation labels. 
                  I iterated on the designs to address these and re-tested to ensure the changes resolved their concerns."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-eleven" className='content-section flex-group flex-center'>
          <ActiveSection index="11" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Hand-off Phase</h2>
                <p>
                  Share detailed specs with developers, provide ongoing support, and ensure the implementation matches the design. We 
                  have regular UX Reviews throughout the development phase. We regularly find issues where things haven't matched the spec. or 
                  where the components haven't been implemented properly.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>

        <Element>
          <section id="section-twelve" className='content-section flex-group flex-center'>
          <ActiveSection index="12" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2 className='dark-grey'>Delivery & Post Launch Phase</h2>
                <p>
                  Monitor the product’s performance post-launch, gather feedback, and plan iterations for future improvements.
                </p>
                <h3>Example:</h3>
                <p className='green'>
                  "After launching StudioHub, we tracked user adoption and collected feedback from labels, which informed 
                  updates like simplifying the onboarding process for new contributors, which then goes into our Sprints as 
                  part of our CI/CD processes. Have experience in both Azure and AWS."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default ProductDesigner;