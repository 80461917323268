import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <section className="content">
      <nav className="projects-menu">
        <div className="projects-menu flex-group flex-center flex-column align-left padding-top-larger">
          <Link to="/"><i></i>Home</Link>
          <Link to="/ProductDesigner"><i></i>Senior Product Designer</Link>
          <Link to="/FrontEndDeveloper"><i></i>Senior Front-end Developer</Link>
        </div>
      </nav>
    </section>
  );
};

export default LandingPage;
