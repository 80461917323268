import React, { Component } from 'react';

class FrontEndDevNav extends Component {
  constructor() {
    super();
    this.state = {
      isActiveIndex: "0",
      show: false,
      showMenu: false
    }
    this.toggleActiveClass.bind(this);
    this.changeActiveMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleActiveClass(index) {
    this.setState({ isActiveIndex: index });
    this.toggleMenu();
  }

  changeActiveMenu(index) {
    if (index >= 0 && index <= 12) {
      this.setState({ isActiveIndex: index });
    }
  }

  toggleMenu() {
    this.setState((prev, props) => {
      return {
        ...prev,
        show: !prev.show
      }
    })
  }

  render() {
    return (
      <div className='interviews-nav-section'>
        <nav className="interviews-nav flex-group flex-column mw10">
            <h4>Past Experience</h4>
            <a className={`${this.state.isActiveIndex === "0" ? 'active' : ''} flex-group flex-center`} href='#section-zero'>15 years experience</a>
            <a className={`${this.state.isActiveIndex === "1" ? 'active' : ''} flex-group flex-center`} href='#section-one'>Design Systems</a>
            <a className={`${this.state.isActiveIndex === "2" ? 'active' : ''} flex-group flex-center`} href='#section-two'>Cross-functional Teams</a>
            <a className={`${this.state.isActiveIndex === "3" ? 'active' : ''} flex-group flex-center`} href='#section-three'>Design Tools/Technical Expertise</a>
            <a className={`${this.state.isActiveIndex === "4" ? 'active' : ''} flex-group flex-center`} href='#section-four'>Problem Solving</a>
            <a className={`${this.state.isActiveIndex === "5" ? 'active' : ''} flex-group flex-center`} href='#section-five'>Teams and Collaboration</a>
            <h4 className='margin-top-large'>Design/Dev Processes</h4>
            <a className={`${this.state.isActiveIndex === "6" ? 'active' : ''} flex-group flex-center`} href='#section-six'>1) Initial Phase</a>
            <a className={`${this.state.isActiveIndex === "7" ? 'active' : ''} flex-group flex-center`} href='#section-seven'>2) Wireframing & Approval</a>
            <a className={`${this.state.isActiveIndex === "8" ? 'active' : ''} flex-group flex-center`} href='#section-eight'>3) Front-end Development</a>
            <a className={`${this.state.isActiveIndex === "9" ? 'active' : ''} flex-group flex-center`} href='#section-nine'>4) Component Creation</a>
            <a className={`${this.state.isActiveIndex === "10" ? 'active' : ''} flex-group flex-center`} href='#section-ten'>5) Testing Phase</a>
            <a className={`${this.state.isActiveIndex === "11" ? 'active' : ''} flex-group flex-center`} href='#section-eleven'>6) Delivery & CI</a>
          </nav>
      </div>
    );
  }
}

export default FrontEndDevNav;
